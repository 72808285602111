
import { readAppConfigs, readUserProfile } from "@/store/main/getters";
import {
  dispatchGetActivePackage,
  dispatchGetUserBinaryTree,
  dispatchGetUserRegistrationsTable,
  dispatchGetUserStructureList,
  dispatchSetUserBinaryStructureSide,
  dispatchGetPurchaseStatisticsByPackages,
} from "@/store/mlm/actions";
import {
  readActivePackage,
  readBinaryTree,
  readRecentRegistrations,
} from "@/store/mlm/getters";
import { Component, Vue } from "vue-property-decorator";
import BinaryTreeNodeUser from "@/components/Main/BinaryTreeNodeUser.vue";
import BinaryTreeNodeHint from "@/components/Main/BinaryTreeNodeHint.vue";
import ListPagination from "@/components/Main/ListPagination.vue";
import ListTeamTree from "@/components/Main/Team/ListTeamTree.vue";
import {
  dispatchGetAllConfigs,
  dispatchGetUserProfile,
} from "@/store/main/actions";
import { IPackageStatisticsResponse } from "@/interfaces/mlm";

@Component({
  components: {
    BinaryTreeNodeUser,
    BinaryTreeNodeHint,
    ListPagination,
    ListTeamTree,
  },
})
export default class Team extends Vue {
  // public showPackagePaymentModal: boolean = false;
  // public selectedPackage: IPackage | null = null;
  public selectedUser: number | null = null;
  public activeTab: string = "structure";
  public side: string = "S";
  public referralLink: string = "";
  public structureList: any = [];
  public structureSearchKeyword: string = "";
  public structureSearchResult: any = [];
  public packagesStatistics: IPackageStatisticsResponse = {
    binary_bonus: 0,
    purchase_statistics: [],
  };
  public page: number = 1;
  public isLoadPackagesStatistics = false;

  public async mounted() {
    dispatchGetAllConfigs(this.$store);
    await dispatchGetUserProfile(this.$store);
    if (this.userProfile) {
      await this.handleLoadBinaryStructure(this.userProfile.id);
      if (this.binaryTree) {
        this.side = this.binaryTree.side_route;
        this.referralLink = this.binaryTree.referral_link;
      }
      this.handleChangeRegsPage(1, true);
    }
    this.structureList = await dispatchGetUserStructureList(this.$store);
    await dispatchGetActivePackage(this.$store);
    this.isLoadPackagesStatistics = true
    this.packagesStatistics = await dispatchGetPurchaseStatisticsByPackages(
      this.$store,
      {}
    );
    this.isLoadPackagesStatistics = false
  }

  public handleSearchInStructure() {
    this.structureSearchResult = [];
    if (this.structureSearchKeyword) {
      for (let i in this.structureList) {
        if (this.structureList[i].label.includes(this.structureSearchKeyword)) {
          this.structureSearchResult.push(this.structureList[i]);
        }
      }
    }
  }

  public async handleChangeRegsPage(page, force = false) {
    if (this.page !== page || force) {
      this.page = page;
      const rowQuery = `?page=${this.page}`;
      // @ts-ignore
      await dispatchGetUserRegistrationsTable(this.$store, {
        data: { user: this.userProfile?.id ?? 0 },
        rowQuery,
      });
    }
  }

  public async handleLoadBinaryStructure(userId) {
    this.structureSearchKeyword = "";
    this.structureSearchResult = [];

    if (userId) {
      this.selectedUser = userId;
      // @ts-ignore
      await dispatchGetUserBinaryTree(this.$store, { user: this.selectedUser });
      // await dispatchGetUserBinaryObject(this.$store, {user: this.selectedUser});
    }
  }

  public async handleChangeBinarySide() {
    const result = await dispatchSetUserBinaryStructureSide(this.$store, {
      side: this.side,
    });
    if (result) {
      // @ts-ignore
      this.$toast.success(this.$t("Branch saved"), {
        duration: 2000,
        position: "bottom",
      });
    } else {
      // @ts-ignore
      this.$toast.error(this.$t("Unable to save"), {
        duration: 2000,
        position: "bottom",
      });
    }
  }

  // public handleOpenPackagePaymentModal(pack) {
  //   this.selectedPackage = pack;
  //   this.showPackagePaymentModal = true;
  // }

  // public handleClosePackagePaymentModal() {
  //   this.showPackagePaymentModal = false;
  //   this.selectedPackage = null;
  // }
  get userProfile() {
    return readUserProfile(this.$store);
  }
  get binaryTree() {
    return readBinaryTree(this.$store);
  }
  get recentRegistrations() {
    return readRecentRegistrations(this.$store);
  }
  get activePackage() {
    return readActivePackage(this.$store);
  }
  get appConfigs() {
    return readAppConfigs(this.$store);
  }
}
